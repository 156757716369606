import { createTheme } from '@mui/material/styles';

// set theme overrides for mui datatables package
const datatableTheme = createTheme({
    MuiTablePagination: {
        styleOverrides: {
            selectLabel: {
                margin: '0 !important',
            },
            displayedRows: {
                margin: '0 !important',
            },
        },
    },
    MUIDataTableHeadCell: {
        styleOverrides: {
            root: {
                fontWeight: 'bold',
            },
        },
    },
    MUIDataTableBodyRow: {
        styleOverrides: {
            root: {
                '&:nth-child(odd)': {
                    backgroundColor: '#E8E8E8',
                },
                '&:hover': {
                    backgroundColor: '#C1E1C1 !important',
                },
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                padding: 5,
                maxWidth: 50,
            },
        },
    },
    MUIDataTable: {
        styleOverrides: {
            responsiveScrollFullHeight: {
                maxHeight: 'none',
                overflow: 'auto',
            },
        },
    },
    MUIDataTableBodyCell: {
        styleOverrides: {
            root: {
                overflow: 'auto',
            },
            responsiveStackedSmallParent: {
                maxWidth: '100%',
            },
        },
    },
    MUIDataTableToolbar: {
        styleOverrides: {
            actions: {
                paddingRight: 20,
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    },
});

export default datatableTheme;
